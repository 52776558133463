import PropTypes from "prop-types";
import styles from "./Menu.module.scss";

function Menu() {
  return <div className={styles.navBar} />;
}

Menu.propTypes = {
  userLoggedIn: PropTypes.bool,
};

export default Menu;
