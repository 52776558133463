import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PlainButton } from "@myloc/myloc-gui";
import { useKeyPress } from "@myloc/myloc-utils";
import { ArrowBack, Dot } from "../../../assets/icons";
import styles from "./PopupCompact.module.scss";
import RoundButton from "../Button/RoundButton/RoundButton";

const PopupCompact = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  useKeyPress(open ? "backbutton" : undefined, () => setOpen(false));

  return (
    <>
      <RoundButton customCssClass={styles.button} onClick={() => setOpen(true)}>
        <div className={styles.content}>
          <Dot size="12" />
          <Dot size="12" />
          <Dot size="12" />
        </div>
      </RoundButton>
      <div className={classNames(styles.popup, open && styles.popupCover)}>
        <header className={styles.header}>
          <PlainButton customCssClass={styles.closeButton} onClick={() => setOpen(false)}>
            <ArrowBack />
          </PlainButton>
          <h1 className={styles.title}>{title}</h1>
        </header>
        <section className={styles.content}>{children}</section>
      </div>
    </>
  );
};

export default PopupCompact;

PopupCompact.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
