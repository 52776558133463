import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FeedbackButton } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import styles from "./Pagination.module.scss";

const Pagination = ({ onClick, onUpdate, pageEnd = false, children, customCssClass }) => {
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();

  async function loadList(e) {
    setLoading(true);
    const listResult = await onClick(e);
    if (listResult) {
      onUpdate(listResult);
    }
    setLoading(false);
  }

  return (
    <div className={classNames(styles.container, customCssClass)}>
      {children}
      <FeedbackButton isLoading={isLoading} onClick={loadList} customCssClass={styles.button} disabled={pageEnd}>
        {translate("SHOW_MORE")}...
      </FeedbackButton>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  onClick: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  pageEnd: PropTypes.bool,
  pageSize: PropTypes.number,
  children: PropTypes.node,
  customCssClass: PropTypes.string,
};
