import { useCallback, useEffect, useMemo, useState } from "react";
import PropType from "prop-types";
import { Form, InputField, FeedbackButton } from "@myloc/myloc-gui";
import { useTablet } from "../../../utils/viewport";
import FilterListIcon from "../../../assets/icons/FilterList";
import { useTranslate } from "../../../language/i18n";
import Button, { THEME } from "../Buttons/Button";
import styles from "./TopFilter.module.scss";

const TopFilter = ({ children, onSubmit, filters, setFilters, fields, label, isLoading }) => {
  const translate = useTranslate();
  const isTablet = useTablet();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const onClickToggleFilters = () => {
    setShowFilters(!showFilters);
    setSearch("");
  };

  const choicesParamsEmpty = params => !Object.values(getChoicesFromParams(params)).find(choice => choice);

  const getChoicesFromParams = useCallback(
    params => {
      return fields.reduce(
        (choice, key) => ({
          ...choice,
          [key]: params.get(key) || "",
        }),
        {},
      );
    },
    [fields],
  );

  const [showFilters, setShowFilters] = useState(!choicesParamsEmpty(params));
  const [search, setSearch] = useState(params.get("q") || "");

  const saveFilters = useCallback(() => {
    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key] != params.get(key)) {
        if (Array.isArray(filters[key])) {
          filters[key].forEach(filter => {
            params.set(key, filter.id);
          });
        } else {
          params.set(key, filters[key]);
        }
      } else if (!filters[key]) {
        params.delete(key);
      }
    });
  }, [filters, params]);

  const clearFilters = useCallback(() => Object.keys(filters).forEach(key => params.delete(key)), [filters, params]);

  useEffect(() => {
    if (search && search !== params.get("q")) {
      params.set("q", search);
    } else if (!search) {
      params.delete("q");
    }

    showFilters ? saveFilters() : clearFilters();
  }, [clearFilters, params, saveFilters, search, showFilters]);

  const clear = () => {
    let _filters = { ...filters };

    for (let key in _filters) {
      _filters[key] = null;
    }

    setFilters(_filters);
  };

  return (
    <section className={styles.topFilter}>
      <Form onSubmit={onSubmit}>
        <div className={styles.generalSearch}>
          {children.find(({ key }) => key == "TopInput") ? (
            children.find(({ key }) => key == "TopInput")
          ) : (
            <InputField
              label={label}
              value={search}
              onChange={event => setSearch(event.target.value)}
              customCssClass={styles.noErrorField}
            />
          )}
          {!isTablet && (
            <FeedbackButton type="button" customCssClass={styles.icon} onClick={onClickToggleFilters}>
              <FilterListIcon customCssClass={styles.space} />
            </FeedbackButton>
          )}
          {!isTablet && (
            <Button isLoading={isLoading} type="submit">
              {translate("SEARCH")}
            </Button>
          )}
        </div>
        {isTablet && (
          <div className={styles.tabletFilterButtons}>
            <FeedbackButton type="button" customCssClass={styles.icon} onClick={onClickToggleFilters}>
              <FilterListIcon customCssClass={styles.space} />
            </FeedbackButton>

            <Button isLoading={isLoading} type="submit">
              {translate("SEARCH")}
            </Button>
          </div>
        )}

        {showFilters && (
          <div className={styles.filters}>
            {children.find(({ key }) => key == "Filter")}

            <div className={styles.searchButtons}>
              <Button type="button" customCssClass={styles.noBorder} theme={THEME.SECONDARY} onClick={clear}>
                {translate("CLEAR")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </section>
  );
};

TopFilter.propTypes = {
  onSubmit: PropType.func.isRequired,
  children: PropType.array,
  filters: PropType.object,
  setFilters: PropType.func,
  fields: PropType.array,
  label: PropType.string,
  isLoading: PropType.bool,
};

export default TopFilter;
