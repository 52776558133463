import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { PlainButton } from "@myloc/myloc-gui";
import { ArrowLeft } from "../../../../assets/icons";
import styles from "./BackButton.module.scss";

const BackButton = ({ children }) => {
  const history = useHistory();

  const onClickBack = e => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <PlainButton onClick={onClickBack} customCssClass={styles.button}>
      <ArrowLeft size={"24"} customCssClass={styles.icon} />
      <div className={styles.container}>{children}</div>
    </PlainButton>
  );
};

export default BackButton;

BackButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
