const pages = Object.freeze({
  HOME: {
    PATH: "/",
    NAME: "HOME",
  },
  PACKAGE_DETAIL: {
    PATH: "/package",
    NAME: "MENU_PACKAGE",
  },
  FACILITY_PACKAGES: {
    PATH: "/facility/packages",
    NAME: "FACILITY_PACKAGES",
  },
  NETWORK: {
    PATH: "/network",
    NAME: "NETWORK",
  },
});

export default pages;
