import { format, fromUnixTime } from "date-fns";

export const FORMAT = {
  DATE: "DATE",
  TIME: "TIME",
  DATETIME: "DATE_TIME",
};

export function formatDateFromTimeMS(time, dateFormat) {
  return formatDateFromUnixTime(parseInt(time / 1000), dateFormat);
}

export function formatDateFromUnixTime(time, dateFormat) {
  return format(fromUnixTime(time), dateFormat);
}
