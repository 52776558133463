import PropTypes from "prop-types";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import facilityService from "../../../services/facility/facilityService";
import { getNetworkPath } from "../../../utils/network";
import pages from "../../../utils/pages";
import SearchData from "../../shared/SearchData/SearchData";

const FacilitySearch = ({ customCssClass }) => {
  const translate = useTranslate();
  const history = useHistory();

  async function onSearch(e, facilityId) {
    e.preventDefault();

    const response = await facilityService.getFacility(facilityId);
    if (response.isOk()) {
      const firstFacility = response.data.facilities?.[0];
      const parameters = new URLSearchParams();
      parameters.append("id", firstFacility?.id);
      const path = await getNetworkPath(pages.FACILITY_PACKAGES);
      history.push({ PATH: path, STATE: firstFacility }, parameters);
    }

    return response;
  }

  return (
    <SearchData
      id="facility-search"
      title={translate("FACILITY_PACKAGE_SEARCH")}
      description={translate("FACILITY_SEARCH_DESCRIPTION")}
      label={translate("FACILITY")}
      onSearch={onSearch}
      customCssClass={customCssClass}
    />
  );
};

export default FacilitySearch;

FacilitySearch.propTypes = {
  customCssClass: PropTypes.string,
};
