import PropTypes from "prop-types";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import packageService from "../../../services/package/packageService";
import { getNetworkPath } from "../../../utils/network";
import pages from "../../../utils/pages";
import SearchData from "../../shared/SearchData/SearchData";

const PackageSearch = ({ customCssClass }) => {
  const translate = useTranslate();
  const history = useHistory();

  async function onSubmit(e, packageNumber) {
    e.preventDefault();

    const response = await packageService.getPackage(null, packageNumber);
    if (response.isOk()) {
      const parameters = new URLSearchParams();
      parameters.append("id", response.data.id);
      const path = await getNetworkPath(pages.PACKAGE_DETAIL);
      history.push({ PATH: path, STATE: response.data }, parameters);
    }

    return response;
  }

  return (
    <SearchData
      id="package-search"
      title={translate("TRACK_PACKAGE")}
      description={translate("TRACK_PACKAGE_DESCRIPTION")}
      label={translate("PACKAGE_ID")}
      buttonLabel={translate("TRACK")}
      onSearch={onSubmit}
      customCssClass={customCssClass}
    />
  );
};

export default PackageSearch;

PackageSearch.propTypes = {
  customCssClass: PropTypes.string,
};
