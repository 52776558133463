import { useEffect, useState, useRef } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect, useLocation } from "react-router-dom";
import classNames from "classnames";

import { resetMessage } from "../../reducers/dialog/dialogAction";
import { parseNetwork, getNetwork, setNetwork } from "../../utils/network";
import { setNetwork as setReducerNetwork } from "../../reducers/appData/appDataActions";

import pages from "../../utils/pages";
import { useMobile } from "../../utils/viewport";

import SnackbarMessage from "../shared/SnackbarMessage/SnackbarMessage";
import StartPage from "../Start/StartPage";
import NotFoundPage from "../NotFound/NotFoundPage";
import NetworkPage from "../Network/NetworkPage";
import PackageDetailPage from "../PackageDetail/PackageDetailPage";
import FacilityPackagesPage from "../Facility/FacilityPackagesPage";

import Header from "./Header/Header";
import MobileHeader from "./Header/MobileHeader/MobileHeader";
import Footer from "./Footer/Footer";
import MobileMenu from "./MobileMenu/MobileMenu";

import styles from "./Site.module.scss";

const PATH_NETWORK = "/:network";

const NavigationContent = () => {
  const isSmallScreen = useMobile();

  const urlNetwork = parseNetwork(window.location.pathname);
  if (urlNetwork) {
    setReducerNetwork(urlNetwork);
    setNetwork(urlNetwork);
  }

  const useInterval = (callback, delayMinutes) => {
    const delay = delayMinutes * 60000;
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useInterval(() => {
    window.location.reload();
  }, 60);

  return (
    <Router>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <main className={classNames(styles.main)}>
        <PageRouter />
        <SnackbarMessage onReset={resetMessage} />
      </main>
      <Footer />
      {isSmallScreen && <MobileMenu />}
    </Router>
  );
};

const PageRouter = () => {
  const location = useLocation();
  const [urlNetwork, setUrlNetwork] = useState(parseNetwork(location.pathname));
  const [currentNetwork, setCurrentNetwork] = useState(urlNetwork);

  useEffect(() => {
    if (urlNetwork !== parseNetwork(location.pathname)) {
      setReducerNetwork(parseNetwork(location.pathname));
      setUrlNetwork(parseNetwork(location.pathname));
    }
  }, [location.pathname, urlNetwork]);

  if (!urlNetwork) {
    loadNetwork();
  }

  async function loadNetwork() {
    if (!urlNetwork) {
      const network = await getNetwork();
      setCurrentNetwork(network);
    }
  }

  return (
    <>
      {urlNetwork ? (
        <Switch>
          <Route exact path={toNetworkPath(pages.HOME)}>
            <StartPage />
          </Route>
          <Route path={toNetworkPath(pages.PACKAGE_DETAIL)}>
            <PackageDetailPage />
          </Route>
          <Route path={toNetworkPath(pages.FACILITY_PACKAGES)}>
            <FacilityPackagesPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      ) : currentNetwork ? (
        <Redirect to={"/" + currentNetwork + "/"} />
      ) : (
        <NetworkPage />
      )}
    </>
  );
};

function toNetworkPath(page) {
  return PATH_NETWORK + page.PATH;
}

export default NavigationContent;
