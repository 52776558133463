import { store as Store } from "@myloc/myloc-utils";

const STORAGE = {
  NETWORK: "network",
};

export function setNetwork(network) {
  Store.storage.save(STORAGE.NETWORK, { id: network });
}

export async function getNetwork() {
  return (await Store.storage.load(STORAGE.NETWORK))?.id;
}

export async function getNetworkPath(page) {
  const network = await getNetwork();
  return toNetworkPath(page, network);
}

export function toNetworkPath(page, network) {
  if (!network) return page.PATH;
  return `/${network}${page.PATH}`;
}

export function parseNetwork(path) {
  const fromIndex = path.indexOf("/");
  const endIndex = path.indexOf("/", fromIndex + 1);
  return path.slice(fromIndex + 1, endIndex > 0 ? endIndex : path.length);
}
