import { Response } from "@myloc/myloc-utils";
import i18n from "../../language/i18n";
import { setError } from "../../reducers/dialog/dialogAction";
import errorCodes from "./errorCodes";

export function handleError(exception, errorHandler) {
  if (!errorHandler) return handleErrorResponse(exception);
  else return errorHandler(exception);
}

const handleErrorResponse = exception => {
  if (exception.response?.status === 401) {
    if (exception.response.data === errorCodes.SESSION_INVALID.CODE) {
      setError({
        error: exception,
        errorMessage: i18n.t(errorCodes.SESSION_INVALID.MESSAGE),
      });

      return new Response(exception.response.status, i18n.t(errorCodes.SESSION_INVALID.MESSAGE), exception);
    }
    //TODO RESET TO HOME PAGE
  }

  const message = getErrorMessage(exception);
  setError({
    error: exception,
    errorMessage: message,
  });

  return new Response(exception.response?.status, message, exception);
};

export function getErrorMessage(exception) {
  if (exception.response?.data) {
    const contentType = exception.response.headers["content-type"]; //MOVE TO some util constant
    if (typeof exception.response.data === "object") {
      return exception.response.data.errorMessage.errorMessage;
    } else if (typeof exception.response.data === "string" && !contentType?.startsWith("test/html")) {
      //Move to some util constant
      return exception.response.data;
    }
  }

  return "Unkown server error";
}
