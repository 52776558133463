import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../shared/Page/Page";
import Welcome from "./Welcome/Welcome";
import styles from "./StartPage.module.scss";

const StartPage = () => {
  let history = useHistory();
  const [, setRedirectTo] = useState(null);

  useEffect(() => {
    if (history?.location?.state?.from) {
      setRedirectTo(history.location.state.from.pathname);
    }
  }, [history]);

  const title = "";
  const text = "";

  return (
    <Page customCssClass={styles.page}>
      <Welcome title={title} text={text} isLoggedIn={false} customCssClass={styles.welcomeSegment} />
    </Page>
  );
};

export default StartPage;
