export const PACKAGE_STATUS_LABEL_ID = {
  1: "STATUS_ADVISED_TO_DISTRIBUTION_CENTER",
  2: "STATUS_RECEIVED_AT_DISTRIBUTION_CENTER",
  3: "STATUS_IN_TRANSIT",
  4: "STATUS_DELIVERED",
};

export function getPackageStatusLabelId(id) {
  const value = PACKAGE_STATUS_LABEL_ID[id];
  return value ? value : "";
}
