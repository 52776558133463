export default class RestOptions {
  set headerOptions(headerOptions) {
    this._headerOptions = headerOptions;
  }

  get headerOptions() {
    return this._headerOptions;
  }

  set errorHandler(errorService) {
    this._errorService = errorService;
  }

  get errorHandler() {
    return this._errorService;
  }

  set errorCallback(errorHandler) {
    this._errorHandler = errorHandler;
  }

  get errorCallback() {
    return this._errorHandler;
  }

  set successfullCallback(successfullCallback) {
    this._successfullCallback = successfullCallback;
  }

  get successfullCallback() {
    return this._successfullCallback;
  }
}
