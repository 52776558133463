import PropTypes from "prop-types";

export function formatPostalNo(postalNo) {
  return format(postalNo?.replace(" ", ""));
}

//TODO with regex?
function format(postalNo) {
  return postalNo?.length === 5 ? postalNo.slice(0, 3) + " " + postalNo.slice(3, 5) : postalNo;
}

formatPostalNo.propTypes = {
  postalNo: PropTypes.string.isRequired,
};
