import { SAVE_SCROLL_POSITION, REMOVE_SCROLL_POSITION } from "./settingVariables";

const initialState = {
  searchString: "",
  savedScrollPositions: [],
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.concat({ key: action.key, position: action.position }),
      };
    case REMOVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.filter(scrollPosition => scrollPosition.key !== action.key),
      };
    default:
      return state;
  }
};

export default settingReducer;
