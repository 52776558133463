import PropTypes from "prop-types";
import { CompactList, List } from "@myloc/myloc-gui";
import { withFetching } from "@myloc/myloc-utils";
import networkService from "../../../services/network/networkService";
import { useTranslate } from "../../../language/i18n";
import { useTablet } from "../../../utils/viewport";
import styles from "./NetworkList.module.scss";

function headerContent(label) {
  return {
    content: <p className={styles.header}>{label}</p>,
  };
}

function mapToColumns(network, onClickNetwork) {
  const onClickRow = onClickNetwork
    ? e => {
        onClickNetwork(e, network);
      }
    : null;
  return [
    {
      content: <p className={styles.column}>{network.id}</p>,
      onClickRow: onClickRow,
    },
    {
      content: <p className={styles.column}>{network.value}</p>,
      onClickRow: onClickRow,
    },
  ];
}

const DesktopNetworkList = ({ networks, onClick }) => {
  const translate = useTranslate();
  const columnSizes = [1, 2];
  const headers = [headerContent(translate("NETWORK")), headerContent(translate("NAME"))];
  const columns = networks ? networks.map(network => mapToColumns(network, onClick)).flat() : [];

  return <List columnSizes={columnSizes} headers={headers} columns={columns} customCssClass={styles.list} />;
};

const CompactNetworkList = ({ networks, onClick }) => {
  const translate = useTranslate();

  const NetworkItem = network => {
    return (
      <div className={styles.item}>
        <p className={styles.title}>{network.id}</p>
        <div className={styles.content}>
          <p className={styles.name}>{translate("NAME")}</p>
          <p>{network.value}</p>
        </div>
      </div>
    );
  };

  return <CompactList items={networks ? networks : []} onClick={onClick} itemTemplate={NetworkItem} />;
};

const NetworkList = ({ ...props }) => {
  const isCompact = useTablet();

  return <> {isCompact ? <CompactNetworkList {...props} /> : <DesktopNetworkList {...props} />} </>;
};

async function loadNetworks() {
  return await networkService.getNetwork();
}

export default withFetching(loadNetworks)(NetworkList);

NetworkList.propTypes = {
  networks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};

DesktopNetworkList.propTypes = {
  networks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};

CompactNetworkList.propTypes = {
  networks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};
