import { useState } from "react";
import PropTypes from "prop-types";
import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import { getNetworkPath } from "../../../utils/network";
import pages from "../../../utils/pages";
import TopFilter from "../../shared/TopFilter/TopFilter";
import TopFilterStyles from "../../shared/TopFilter/TopFilter.module.scss";
import styles from "./FacilityPackageSearchFilter.module.scss";

const FILTERS = ["fromDate", "toDate"];

const getSelectedFiltersFromParams = availableFilters => {
  if (!availableFilters) {
    return;
  }
  return availableFilters;
};

const FacilityPackageSearchFilter = ({ onSearch }) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const AVAILABLE_FILTERS = {
    search: [],
    fromDate: "",
    toDate: "",
  };

  const [selectedFilters, setSelectedFilters] = useState(getSelectedFiltersFromParams(AVAILABLE_FILTERS));

  async function onSubmit() {
    setLoading(true);
    const parameters = new URLSearchParams(window.location.search);
    const facilityId = parameters.get("id");

    if (facilityId) {
      selectedFilters?.search ? parameters.set("search", selectedFilters?.search) : parameters.set("search", "");

      if (selectedFilters?.fromDate) {
        parameters.set("fromDate", selectedFilters?.fromDate);
      }

      if (selectedFilters?.toDate) parameters.set("toDate", selectedFilters?.toDate);

      const path = await getNetworkPath(pages.FACILITY_PACKAGES);
      history.replace({ PATH: path, STATE: facilityId }, parameters);

      await onSearch();
    }
    setLoading(false);
  }

  return (
    <TopFilter
      onSubmit={onSubmit}
      filters={selectedFilters}
      setFilters={setSelectedFilters}
      fields={FILTERS}
      label={translate("FACILITY_PACKAGE_SEARCH_FILTER")}
      className={styles.topFilter}
      isLoading={isLoading}
    >
      <InputField
        type="text"
        key="TopInput"
        label={translate("FACILITY_PACKAGE_SEARCH_FILTER")}
        value={selectedFilters?.search}
        customCssClass={TopFilterStyles.noErrorField}
        onChange={option => setSelectedFilters({ ...selectedFilters, search: option?.target?.value })}
      />

      <div className={styles.searchGroup} key="Filter">
        <InputField
          type="date"
          label={translate("FROM_DATE")}
          labelAlwaysTop
          value={selectedFilters?.fromDate}
          onChange={option => setSelectedFilters({ ...selectedFilters, fromDate: option?.target?.value })}
        />
        <InputField
          type="date"
          label={translate("TO_DATE")}
          labelAlwaysTop
          value={selectedFilters?.toDate}
          onChange={option => setSelectedFilters({ ...selectedFilters, toDate: option?.target?.value })}
        />
      </div>
    </TopFilter>
  );
};

export default FacilityPackageSearchFilter;

FacilityPackageSearchFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
