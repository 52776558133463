import { Link, useLocation } from "react-router-dom";
import logo from "../../../../assets/images/Myloc-logga.svg";
import pages from "../../../../utils/pages";
import { parseNetwork, toNetworkPath } from "../../../../utils/network";
import styles from "./MobileHeader.module.scss";

function MobileHeader() {
  const location = useLocation();
  const network = parseNetwork(location.pathname);
  return (
    <header className={styles.header}>
      <figure className={styles.logoContainer}>
        <Link to={toNetworkPath(pages.HOME, network)}>
          <img src={logo} alt="Myloc logo" className={styles.logo} />
        </Link>
      </figure>
    </header>
  );
}

export default MobileHeader;
