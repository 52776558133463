import { NETWORK } from "./appDataVariables";

const initialState = {
  network: null,
};

export const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK:
      return Object.assign({}, state, { network: action.network });
    default:
      return state;
  }
};
