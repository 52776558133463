import { HeaderOptions, HEADER } from "@myloc/myloc-utils";
import { api_key } from "../../config/settings";
import i18n from "../../language/i18n";
import { getNetwork } from "../../utils/network";
import RestOptions from "./RestOptions";

export const HEADER_ADDON = {
  API_KEY: "api_key",
  NETWORK: "network",
};

const noErrorRestOptions = async () => {
  const options = new RestOptions();

  options.headerOptions = new HeaderOptions();
  options.headerOptions.setHeader(HEADER.LANGUAGE, i18n.language);
  options.headerOptions.setHeader(HEADER_ADDON.API_KEY, api_key());
  options.headerOptions.setHeader(HEADER_ADDON.NETWORK, await getNetwork());

  return options;
};

export default noErrorRestOptions;
