import { dialogVariables } from "@myloc/myloc-utils";
import store from "../rootReducer";

export function setError(error) {
  store.dispatch({ type: dialogVariables.ACTION.MESSAGE_ERROR, error: error?.error, message: error?.errorMessage });
}

export function setMessage(message) {
  store.dispatch({ type: dialogVariables.ACTION.MESSAGE_INFO, message: message });
}

export function resetMessage() {
  store.dispatch({ type: dialogVariables.ACTION.RESET_MESSAGE });
}
