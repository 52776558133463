import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/Myloc-logga.svg";
import { parseNetwork, toNetworkPath } from "../../../utils/network";
import pages from "../../../utils/pages";
import Menu from "./Menu/Menu";
import styles from "./Header.module.scss";

function Header() {
  const location = useLocation();
  const network = parseNetwork(location.pathname);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <Link to={toNetworkPath(pages.HOME, network)}>
          <img src={logo} alt="Myloc logo" className={styles.logo} />
        </Link>
      </div>
      <Menu />
    </header>
  );
}

export default Header;
