import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";
import { DEFAULT_QUERY_PARAM, QUERY_PARAM } from "../utils/pagingation";

async function getPackage(packageId, packageNumber) {
  const url = api.package.search();
  if (packageId) url.addParameter("id", packageId);
  if (packageNumber) url.addParameter("packagenumber", packageNumber);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getPackagePaging(
  facilityId,
  search,
  fromDate,
  toDate,
  from = DEFAULT_QUERY_PARAM.FROM,
  amount = DEFAULT_QUERY_PARAM.AMOUNT,
) {
  const url = api.package.search();
  if (facilityId) url.addParameter("facilityid", facilityId);
  if (search) url.addParameter("search", search);
  if (fromDate) url.addParameter("fromDate", fromDate);
  if (toDate) url.addParameter("toDate", toDate);

  url.addParameter(QUERY_PARAM.FROM, from);
  url.addParameter(QUERY_PARAM.AMOUNT, amount);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getPackage, getPackagePaging };
