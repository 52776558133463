import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormat, useTranslate } from "../../../language/i18n";
import { FORMAT, formatDateFromTimeMS } from "../../../language/date";
import { getPackageStatusLabelId } from "../../../utils/packageStatus";
import styles from "./PackageInformation.module.scss";

const PackageInformation = ({
  status,
  plannedDeliveryDate,
  plannedDeliveryTime,
  recievedDate,
  recievedTime,
  facility,
  supplier,
  order,
  customCssClass,
}) => {
  const translate = useTranslate();
  const format = useFormat();

  return (
    <section className={classNames(styles.container, customCssClass)}>
      <h2 className={styles.title}>{translate("INFORMATION_ABOUT_DELIVERY")}</h2>
      <article>
        <Label label={translate("FACILITY")} value={facility?.value} />
        <Label label={translate("ORDER")} value={order} />
        <Label label={translate("CURRENT_STATUS")} value={translate(getPackageStatusLabelId(status))} />
        <Label
          label={translate("PLANNED_DELIVERY_DATE")}
          value={plannedDeliveryDate ? formatDateFromTimeMS(plannedDeliveryDate, format(FORMAT.DATE)) : ""}
        />
        <Label
          label={translate("PLANNED_DELIVERY_TIME")}
          value={plannedDeliveryTime ? formatDateFromTimeMS(plannedDeliveryTime, format(FORMAT.TIME)) : ""}
        />
        <Label
          label={translate("DELIVERY_DATE")}
          value={recievedDate ? formatDateFromTimeMS(recievedDate, format(FORMAT.DATE)) : ""}
        />
        <Label
          label={translate("DELIVERY_TIME")}
          value={recievedTime ? formatDateFromTimeMS(recievedTime, format(FORMAT.TIME)) : ""}
        />
        <Label label={translate("SUPPLIER")} value={supplier?.value} />
      </article>
    </section>
  );
};

const Label = ({ label, value }) => (
  <div className={styles.text}>
    <p className={styles.label}>{label}: </p>
    <p className={styles.noneBreakingPart}>{value}</p>
  </div>
);

export default PackageInformation;

Label.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

PackageInformation.propTypes = {
  packageNumber: PropTypes.string,
  order: PropTypes.string,
  status: PropTypes.number,
  plannedDeliveryDate: PropTypes.number,
  plannedDeliveryTime: PropTypes.number,
  recievedDate: PropTypes.number,
  recievedTime: PropTypes.number,
  facility: PropTypes.shape({
    value: PropTypes.string,
  }),
  supplier: PropTypes.shape({
    value: PropTypes.string,
  }),
  customCssClass: PropTypes.string,
};
