import PropTypes from "prop-types";
import { withFetching, withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import packageService from "../../services/package/packageService";
import { useTablet } from "../../utils/viewport";
import BackButton from "../shared/Button/BackButton/BackButton";
import PopupCompact from "../shared/PopupCompact/PopupCompact";
import PackageStatus from "../shared/PackageStatus/PackageStatus";
import Page from "../shared/Page/Page";
import PackageInformation from "./PackageInformation/PackageInformation";
import styles from "./PackageDetailPage.module.scss";
import Section, { Layout } from "../shared/Section/Section";

const PackageDetailPage = ({ packageNumber, status, ...props }) => {
  const isCompact = useTablet();
  const translate = useTranslate();

  const statuses = [1, 2, 3, 4];

  return (
    <Page>
      <BackButton>
        <h1 className={styles.title}>{translate("TRACK_PACKAGE")}</h1>
      </BackButton>
      <Section layout={Layout.FOUR_TO_ONE} spaceBetween>
        <PackageStatus
          title={`${translate("PACKAGE_NUMBER")}: ${packageNumber}`}
          statuses={statuses}
          occured={status}
        />

        {isCompact ? (
          <PopupCompact title={translate("INFORMATION")}>
            <PackageInformation packageNumber={packageNumber} status={status} {...props} />
          </PopupCompact>
        ) : (
          <PackageInformation packageNumber={packageNumber} status={status} {...props} />
        )}
      </Section>
    </Page>
  );
};

PackageDetailPage.propTypes = {
  packageNumber: PropTypes.string,
  order: PropTypes.string,
  plannedDeliveryDate: PropTypes.number,
  plannedDeliveryTime: PropTypes.number,
  recievedDate: PropTypes.number,
  recievedTime: PropTypes.number,
  status: PropTypes.number,
  supplier: PropTypes.object,
};

async function loadPackage({ search }) {
  const params = new URLSearchParams(search);
  const id = params.get("id");
  return await packageService.getPackage(id);
}

export default withPage(withFetching(loadPackage)(PackageDetailPage));
