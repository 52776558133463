import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Section.module.scss";

const Section = ({ layout, spaceBetween, children, customCssClass }) => (
  <section className={classNames(styles.section, layout, spaceBetween && styles.spaceBetween, customCssClass)}>
    {children}
  </section>
);

export const Layout = {
  ONE_TO_ONE: styles.oneToOne,
  THREE_TO_TWO: styles.threeToTwo,
  FOUR_TO_ONE: styles.fourToOne,
};

Section.propTypes = {
  layout: PropTypes.oneOf([Layout.ONE_TO_ONE, Layout.THREE_TO_TWO, Layout.FOUR_TO_ONE]),
  spaceBetween: PropTypes.bool,
  customCssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default Section;
