import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Response, useHistory, withFetching, withPage } from "@myloc/myloc-utils";
import packageService from "../../services/package/packageService";
import facilityService from "../../services/facility/facilityService";
import { useTranslate } from "../../language/i18n";
import { getNetworkPath } from "../../utils/network";
import { DEFAULT_QUERY_PARAM } from "../../services/utils/pagingation";
import pages from "../../utils/pages";
import BackButton from "../shared/Button/BackButton/BackButton";
import Page from "../shared/Page/Page";
import Pagination from "../shared/Pagination/Pagination";
import PackageList from "./PackageList/PackageList";
import styles from "./FacilityPackagesPage.module.scss";
import FacilityPackageSearchFilter from "./Search/FacilityPackageSearchFilter";

const DEFAULT = {
  PAGE_COUNTER: 1,
  PAGE_INCRAMENT: 1,
};

const FacilityPackagePage = ({ value, packages, search }) => {
  const [packageList, setPackages] = useState([]);
  const [isPageEnd, setPageEnd] = useState(false);
  const [pageCounter, setPagecounter] = useState(DEFAULT.PAGE_COUNTER);

  const translate = useTranslate();
  const history = useHistory();

  const updatePackageList = useCallback(
    packages => {
      const newPackageList = packageList.concat(
        packages.filter(
          packageData => !packageList.some(currentPackageData => currentPackageData.id === packageData.id),
        ),
      );
      if (newPackageList.length > packageList.length) {
        setPackages(newPackageList);
      }
    },
    [packageList],
  );

  useEffect(() => {
    if (packages) {
      const parameters = new URLSearchParams(window.location.search);
      if (!parameters.get("search")) {
        updatePackageList(packages);
      }
    }
  }, [packages, updatePackageList]);

  async function next() {
    const params = new URLSearchParams(search);
    const facilityId = params.get("id");
    const filterSearch = params.get("search");
    const fromDate = params.get("fromDate");
    const toDate = params.get("toDate");
    const response = await packageService.getPackagePaging(
      facilityId,
      filterSearch,
      fromDate,
      toDate,
      pageCounter * DEFAULT_QUERY_PARAM.AMOUNT,
    );
    if (response.isOk()) {
      setPagecounter(pageCounter + DEFAULT.PAGE_INCRAMENT);
      if (response.data.packages?.length < DEFAULT_QUERY_PARAM.AMOUNT) {
        setPageEnd(true);
      }

      return response.data.packages;
    }
  }

  const onClick = async (e, item) => {
    e.preventDefault();
    const parameters = new URLSearchParams();
    parameters.append("id", item.id);
    const path = await getNetworkPath(pages.PACKAGE_DETAIL);
    history.push({ PATH: path, STATE: item }, parameters);
  };

  const onFilterSearch = async () => {
    const response = await loadPackagesAfterSearch();
    if (response.isOk()) {
      setPackages([]);
      setPackages(response.data.packages);
      setPagecounter(DEFAULT.PAGE_COUNTER);
      if (response.data.packages?.length < DEFAULT_QUERY_PARAM.AMOUNT) {
        setPageEnd(true);
      } else {
        setPageEnd(false);
      }
    }
  };

  return (
    <Page customCssClass={styles.page}>
      <BackButton>
        <h1 className={styles.title}>{translate("FACILITY")}</h1>
        <p className={styles.text}>{value}</p>
      </BackButton>
      <FacilityPackageSearchFilter onSearch={onFilterSearch} />
      <Pagination onClick={next} onUpdate={updatePackageList} pageEnd={isPageEnd}>
        <PackageList packages={packageList} onClick={onClick} />
      </Pagination>
    </Page>
  );
};

FacilityPackagePage.propTypes = {
  value: PropTypes.string,
  packages: PropTypes.array,
  search: PropTypes.string,
};

async function loadFacility({ search }) {
  const params = new URLSearchParams(search);
  const facilityId = params.get("id");
  const response = await facilityService.getFacility(facilityId);
  return new Response(response.statusCode, response.message, response.data?.facilities?.[0]);
}

async function loadPackages({ search }) {
  const params = new URLSearchParams(search);
  const facilityId = params.get("id");
  const filterSearch = params.get("search");
  const fromDate = params.get("fromDate");
  const toDate = params.get("toDate");

  return await packageService.getPackagePaging(facilityId, filterSearch, fromDate, toDate);
}

async function loadPackagesAfterSearch() {
  const parameters = new URLSearchParams(window.location.search);
  const facilityId = parameters.get("id");
  const search = parameters.get("search");
  const fromDate = parameters.get("fromDate");
  const toDate = parameters.get("toDate");

  return await packageService.getPackagePaging(facilityId, search, fromDate, toDate);
}

export default withPage(withFetching([loadFacility, loadPackages])(FacilityPackagePage));
