import PropTypes from "prop-types";
import { List, CompactList } from "@myloc/myloc-gui";
import { FORMAT, formatDateFromTimeMS } from "../../../language/date";
import { useFormat, useTranslate } from "../../../language/i18n";
import { useTablet } from "../../../utils/viewport";
import { getPackageStatusLabelId } from "../../../utils/packageStatus";
import styles from "./PackageList.module.scss";

function dateString(dateInMs, dateFormat) {
  return dateInMs <= 0 ? "- -" : formatDateFromTimeMS(dateInMs, dateFormat);
}

function dateContent(dateInMs, dateFormat, onClickRow) {
  return {
    content: <p className={styles.column}>{dateInMs <= 0 ? "- -" : formatDateFromTimeMS(dateInMs, dateFormat)}</p>,
    onClickRow: onClickRow,
  };
}

function headerContent(label) {
  return {
    content: <p className={styles.header}>{label}</p>,
  };
}

function mapToColumns(packageData, onClickPackage, dateFormat) {
  const onClickRow = onClickPackage
    ? e => {
        onClickPackage(e, packageData);
      }
    : null;

  return [
    {
      content: <p className={styles.column}>{packageData.supplier?.value}</p>,
      onClickRow: onClickRow,
    },
    {
      content: <p className={styles.column}>{packageData.order}</p>,
      onClickRow: onClickRow,
    },
    {
      content: <p className={styles.packageColumn}>{packageData.packageNumber}</p>,
      onClickRow: onClickRow,
    },
    {
      content: <p className={styles.column}>{packageData.statusValue}</p>,
      onClickRow: onClickRow,
    },
    dateContent(packageData.plannedDeliveryDate, dateFormat, onClickRow),
    dateContent(packageData.recievedDate, dateFormat, onClickRow),
  ];
}

const DesktopPackageList = ({ packages, onClick }) => {
  const translate = useTranslate();
  const format = useFormat();
  const columnSizes = [1, 1, 1, 1, 1, 1];
  const headers = [
    headerContent(translate("SUPPLIER")),
    headerContent(translate("ORDER")),
    headerContent(translate("PACKAGE_NUMBER")),
    headerContent(translate("CURRENT_STATUS")),
    headerContent(translate("PLANNED_DELIVERY_DATE")),
    headerContent(translate("DELIVERY_DATE")),
  ];

  const columns = packages
    .map(packageData =>
      mapToColumns(
        Object.assign({}, packageData, { statusValue: translate(getPackageStatusLabelId(packageData.status)) }),
        onClick,
        format(FORMAT.DATE),
      ),
    )
    .flat();

  return <List columnSizes={columnSizes} headers={headers} columns={columns} customCssClass={styles.list} />;
};

const CompactPackageList = ({ packages, onClick }) => {
  const translate = useTranslate();
  const format = useFormat();
  const PackageItem = packageData => {
    return (
      <div className={styles.item}>
        <p className={styles.title}>{packageData.packageNumber}</p>
        <div className={styles.content}>
          <p className={styles.name}>{translate("SUPPLIER")}</p>
          <p>{packageData.supplier?.value}</p>

          <p className={styles.name}>{translate("ORDER")}</p>
          <p>{packageData.order?.value}</p>

          <p className={styles.name}>{translate("CURRENT_STATUS")}</p>
          <p>{translate(getPackageStatusLabelId(packageData.status))}</p>

          <p className={styles.name}>{translate("PLANNED_DELIVERY_DATE")}</p>
          <p>{dateString(packageData.plannedDeliveryDate, format(FORMAT.DATE))}</p>

          <p className={styles.name}>{translate("DELIVERY_DATE")}</p>
          <p>{dateString(packageData.recievedDate, format(FORMAT.DATE))}</p>
        </div>
      </div>
    );
  };

  return <CompactList items={packages} onClick={onClick} itemTemplate={PackageItem} />;
};

const PackageList = ({ ...props }) => {
  const isTablet = useTablet();

  return <>{!isTablet ? <DesktopPackageList {...props} /> : <CompactPackageList {...props} />}</>;
};

export default PackageList;

PackageList.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
};

DesktopPackageList.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
};

CompactPackageList.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
};
