import PropTypes from "prop-types";
import classNames from "classnames";
import { myloc } from "../../../config/settings";
import { useTranslate } from "../../../language/i18n";
import { formatPostalNo } from "../../../utils/postalNo";
import ExternalLink, { Target, Type } from "../../shared/ExternalLink/ExternalLink";
import styles from "./Footer.module.scss";
import footerService from "../../../services/footer/footerService";
import { Response, withFetching } from "@myloc/myloc-utils";
import { useSelector } from "react-redux";

const defaultData = {
  mail: myloc.contactEmail.toString,
  phone: myloc.contactPhone.toString,
  website: myloc.webpageUrl.toString,
  supportmail: myloc.supportEmail.toString,
  address: myloc.address.street,
  city: `${formatPostalNo(myloc.address.postalNo)} ${myloc.address.city}`,
  county: myloc.address.county,
};

function Footer() {
  const network = useSelector(state => state.appData?.network);
  return <FooterContentReciver network={network} />;
}

const load = async ({ network }, prevProps) => {
  if (!network) {
    return new Response(200, "", defaultData);
  }

  if (network !== prevProps?.network) {
    const response = await footerService.getFooter(network);
    if (response.statusCode != 200) {
      return new Response(200, "", defaultData);
    }
    return response;
  }
};

const FooterContent = ({ mail, phone, website, supportmail, address, city, county }) => (
  <footer className={styles.footer}>
    <div className={classNames(styles.content, styles.containerPadding)}>
      <Contact mail={mail} phone={phone} website={website} />
      <Links supportmail={supportmail} />
      <Address address={address} city={city} county={county} />
    </div>
  </footer>
);

const FooterContentReciver = withFetching(load)(FooterContent);

const Contact = ({ mail, phone, website }) => {
  const translate = useTranslate();
  return (
    <div>
      <Title>{translate("CONTACT")}</Title>
      <ExternalLink url={mail} type={Type.EMAIL} customCssClass={styles.text}>
        {mail}
      </ExternalLink>
      <ExternalLink url={phone} type={Type.PHONE} customCssClass={styles.text}>
        {phone}
      </ExternalLink>
      <ExternalLink url={website} target={Target.NEW_TAB}>
        {website}
      </ExternalLink>
    </div>
  );
};

const Links = ({ supportmail }) => {
  const translate = useTranslate();
  return (
    <div className={classNames(styles.section)}>
      <Title>{translate("SUPPORT")}</Title>
      <ExternalLink url={supportmail} type={Type.EMAIL} customCssClass={classNames(styles.text, styles.textMargin)}>
        {supportmail}
      </ExternalLink>
      <ExternalLink url={myloc.cookiIntegrityUrl} target={Target.NEW_TAB} customCssClass={styles.text}>
        {translate("COOKIE_POLICY")}
      </ExternalLink>
    </div>
  );
};

const Address = ({ address, city, county }) => {
  const translate = useTranslate();
  return (
    <div className={styles.address}>
      <Title>{translate("ADDRESS")}</Title>
      <Text>{address}</Text>
      <Text>{city}</Text>
      <Text>{county}</Text>
    </div>
  );
};

const Title = ({ children }) => <p className={styles.title}>{children}</p>;
const Text = ({ children }) => <p className={styles.text}>{children}</p>;

Footer.propTypes = {
  session: PropTypes.object,
  user: PropTypes.object,
};

FooterContentReciver.propTypes = {
  network: PropTypes.string,
};

FooterContent.propTypes = {
  mail: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  supportmail: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
};

Contact.propTypes = {
  mail: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
};

Links.propTypes = {
  supportmail: PropTypes.string,
};

Address.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default Footer;
