import { useHistory } from "@myloc/myloc-utils";
import NetworkList from "./NetworkList/NetworkList";
import Page from "../shared/Page/Page";
import { setNetwork, toNetworkPath } from "../../utils/network";
import pages from "../../utils/pages";

const NetworkPage = () => {
  const history = useHistory();
  async function onSelect(e, network) {
    e.preventDefault();
    setNetwork(network.id);
    history.push({ PATH: toNetworkPath(pages.HOME, network.id) }, "");
  }

  return (
    <Page>
      <NetworkList onClick={onSelect} />
    </Page>
  );
};

export default NetworkPage;
