import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RoundButton.module.scss";

function RoundButton({ onClick, children, disabled, customCssClass }) {
  return (
    <button className={classNames(styles.roundButton, customCssClass)} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

RoundButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  customCssClass: PropTypes.string,
};

export default RoundButton;
