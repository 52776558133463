import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import noErrorRestOptions from "../utils/noErrorRestOptions";

async function getFooter(network) {
  const url = api.footer.footer();
  url.addParameter("network", network);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await noErrorRestOptions());
}

export default { getFooter };
