import PropTypes from "prop-types";
import classNames from "classnames";
import Section, { Layout } from "../Section/Section";
import styles from "./Page.module.scss";

const Page = ({ customCssClass, ...props }) => (
  <Section customCssClass={classNames(styles.page, customCssClass)} {...props} />
);

Page.propTypes = {
  layout: PropTypes.oneOf([Layout.ONE_TO_ONE, Layout.THREE_TO_TWO]),
  spaceBetween: PropTypes.bool,
  customCssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export { Layout };
export default Page;
