import PropTypes from "prop-types";
import styles from "./Image.module.scss";

const Image = ({ ratio = AspectRatio.OneToOne, alt, ...props }) => (
  <div className={styles.container}>
    <div className={ratio}>
      <img alt={alt ? alt : ""} {...props} className={styles.image}></img>
    </div>
  </div>
);

export const AspectRatio = {
  OneToOne: styles.innerContainerOneToOne,
  OneToSix: styles.innerContainerOneToSix,
  TwoToOne: styles.innerContainerTwoToOne,
  TwoToFive: styles.innerContainerTwoToFive,
  FiveToSix: styles.innerContainerFiveToSix,
};

Image.propTypes = {
  ratio: PropTypes.oneOf([
    AspectRatio.OneToOne,
    AspectRatio.OneToSix,
    AspectRatio.TwoToOne,
    AspectRatio.TwoToFive,
    AspectRatio.FiveToSix,
  ]),
  alt: PropTypes.string,
};

export default Image;
