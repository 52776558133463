import { createStore } from "redux";
import { createRecuder } from "@myloc/myloc-utils";
import settingReducer from "./setting/settingReducer";
import { sessionReducer } from "./session/sessionReducer";
import { appDataReducer } from "./appData/appDataReducer";

const appReducer = createRecuder({
  session: sessionReducer,
  setting: settingReducer,
  appData: appDataReducer,
});

const reducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(reducer);

export default store;
