import PropTypes from "prop-types";
import classNames from "classnames";
import logo from "../../../assets/icons/uppsala_logo.svg";
import Image, { AspectRatio } from "../../shared/Image/Image";
import PackageSearch from "../PackageSearch/PackageSearch";
import FacilitySearch from "../FacilitySearch/FacilitySearch";
import styles from "./Welcome.module.scss";

const Welcome = ({ customCssClass }) => {
  return (
    <section className={classNames(styles.container, customCssClass)}>
      <article className={classNames(styles.contentContainer, styles.textContainer)}>
        <FacilitySearch />
      </article>
      <figure className={styles.imageContainer}>
        <Image ratio={AspectRatio.FiveToSix} src={logo} alt="Logo" />
      </figure>
      <article className={classNames(styles.contentContainer, styles.infoContainer)}>
        <PackageSearch />
      </article>
    </section>
  );
};

Welcome.propTypes = {
  customCssClass: PropTypes.string,
};

export default Welcome;
