import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { dialogVariables } from "@myloc/myloc-utils";
import { Snackbar, Severity } from "@myloc/myloc-gui";

const SnackbarMessage = ({ onReset }) => {
  const message = useSelector(state => state.dialog.message);

  return (
    <Snackbar
      severity={message.type === dialogVariables.MESSAGE_TYPE.ERROR ? Severity.Error : Severity.Success}
      onClose={onReset}
      message={message?.value}
    />
  );
};

SnackbarMessage.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default SnackbarMessage;
