import PropTypes from "prop-types";
import classNames from "classnames";
import Spinner from "../../../assets/images/spinner.gif";
import styles from "./Button.module.scss";

const THEME = {
  PRIMARY: styles.primary,
  SECONDARY: styles.secondary,
  GREY: styles.grey,
  PLAIN: styles.plain,
  PLAIN_PRIMARY: styles.plainPrimary,
};

const Button = ({ theme = THEME.PRIMARY, customCssClass, isLoading, type = "button", ...props }) => {
  return (
    <button className={classNames(styles.button, theme, customCssClass)} type={type} {...props}>
      {isLoading ? (
        <div className={styles.spinner}>
          <img src={Spinner} />
        </div>
      ) : (
        <>{props.children}</>
      )}
    </button>
  );
};

Button.propTypes = {
  theme: PropTypes.oneOf([THEME.PRIMARY, THEME.SECONDARY, THEME.GREY, THEME.PLAIN, THEME.PLAIN_PRIMARY]),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  customCssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  type: PropTypes.string,
};

export default Button;
export { THEME };
