import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslate } from "../../../language/i18n";
import { getPackageStatusLabelId } from "../../../utils/packageStatus";
import styles from "./PackageStatus.module.scss";

const Status = ({ status, isLast, hasOccured, text }) => {
  return (
    <div className={styles.status}>
      <div className={classNames(styles.statusIcon, hasOccured && styles.occuredStatus)}>
        {!isLast && <div className={classNames(styles.line, hasOccured && styles.occuredLine)} />}
        {status}
      </div>
      <p className={classNames(hasOccured && styles.occuredText)}>{text}</p>
    </div>
  );
};

const PackageStatus = ({ title, statuses, occured, customCssClass }) => {
  const translate = useTranslate();
  return (
    <div className={classNames(styles.container, customCssClass)}>
      {!!title && <h3 className={styles.title}>{title}</h3>}
      <div className={classNames(styles.content)}>
        {statuses.map((status, index) => (
          <Status
            key={index}
            status={status}
            hasOccured={occured >= status}
            isLast={statuses.length === index + 1}
            text={translate(getPackageStatusLabelId(status))}
          />
        ))}
      </div>
    </div>
  );
};

export default PackageStatus;

PackageStatus.propTypes = {
  title: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.number).isRequired,
  occured: PropTypes.number.isRequired,
  customCssClass: PropTypes.string,
};

Status.propTypes = {
  status: PropTypes.number.isRequired,
  hasOccured: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
