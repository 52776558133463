import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FeedbackButton, Form, InputField } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import styles from "./SearchData.module.scss";

const SearchData = ({ id, title, description, label, buttonLabel, searchValuePattern, onSearch, customCssClass }) => {
  const translate = useTranslate();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function onSubmit(e) {
    setLoading(true);

    const response = await onSearch(e, searchValue);
    if (!response?.isOk()) {
      setErrorMessage(response.message);
      setLoading(false);
    }
  }

  const onChange = e => {
    e.preventDefault();
    setSearchValue(e.currentTarget.value);
    setErrorMessage("");
  };

  return (
    <Form id={id} className={classNames(styles.form, customCssClass)} onSubmit={onSubmit}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{description}</p>
      <section className={styles.bottom}>
        <InputField
          required
          label={label}
          value={searchValue}
          onChange={onChange}
          pattern={searchValuePattern}
          customInvalidMessage={errorMessage}
        />
        <FeedbackButton customCssClass={styles.button} type="submit" isLoading={isLoading}>
          {buttonLabel ? buttonLabel : translate("SEARCH")}
        </FeedbackButton>
      </section>
    </Form>
  );
};

export default SearchData;

SearchData.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searchValuePattern: PropTypes.string,
  customCssClass: PropTypes.string,
};
