import { URL } from "@myloc/myloc-utils";

const root = () => process.env.REACT_APP_API_URL;
export const api_key = () => process.env.REACT_APP_API_KEY;

const api_network = () => root() + "/public/network/";
const api_package = () => root() + "/public/package/";
const api_facility = () => root() + "/public/facility/";
const api_footer = () => root() + "/public/footer/";

export const api = {
  network: {
    network: () => new URL(api_network()),
  },
  package: {
    search: () => new URL(api_package()),
  },
  facility: {
    facility: () => new URL(api_facility()),
  },
  footer: {
    footer: () => new URL(api_footer()),
  },
};

export const myloc = {
  webpageUrl: new URL("https://myloc.se"),
  cookiIntegrityUrl: new URL("https://myloc.se"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support.logistics@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
};
