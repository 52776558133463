import { NavLink, useLocation } from "react-router-dom";
import { Home } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import { parseNetwork, toNetworkPath } from "../../../utils/network";
import pages from "../../../utils/pages";
import styles from "./MobileMenu.module.scss";

function MobileMenu() {
  const translate = useTranslate();
  const location = useLocation();
  const network = parseNetwork(location.pathname);

  return (
    <section className={styles.menu}>
      <NavLink exact to={toNetworkPath(pages.HOME, network)} className={styles.link} activeClassName={styles.active}>
        <Home />
        <span className={styles.text}>{translate("HOME")}</span>
      </NavLink>
    </section>
  );
}

export default MobileMenu;
